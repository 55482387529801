body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  color: $color-gray-dark;
}

$font-inter: 'Inter';

.h1-bold {
  font-size: 18px;
  font-weight: bold;
}

.h2-bold {
  font-size: 16px;
  font-weight: bold;
}

.h3-bold {
  font-size: 14px;
  font-weight: bold;
}

.h4-bold {
  font-size: 12px;
  font-weight: bold;
}

.h5-bold {
  font-size: 10px;
  font-weight: bold;
}

.text-b-xl {
  font-size: 16px;
  font-weight: 900;
}

.text-b-l {
  font-size: 14px;
  font-weight: 900;
}

.text-b-m {
  font-size: 12px;
  font-weight: 900;
}

.text-b-s {
  font-size: 10px;
  font-weight: 900;
}

.text-m-xl {
  font-size: 16px;
  font-weight: 700;
}

.text-m-l {
  font-size: 14px;
  font-weight: 700;
}

.text-m-m {
  font-size: 12px;
  font-weight: 700;
}

.text-m-s {
  font-size: 10px;
  font-weight: 700;
}

.text-xl {
  font-size: 16px;
  font-weight: 500;
}

.text-l {
  font-size: 14px;
  font-weight: 500;
}

.text-s {
  font-size: 12px;
  font-weight: 500;
}

.text-xs {
  font-size: 8px;
  font-weight: 500;
}
