.input {
  display: grid;
  margin-top: 1em;

  label {
    font-family: $font-inter;
    color: $color-gray-dark;
    margin-bottom: 0.1em;
  }

  input {
    background: $color-gradient-light !important;
    margin: 0;
    padding: 0.6em;
    border: none;
    border-radius: 16px;
    color: $color-gray-dark;
    font-family: $font-inter;
    font-weight: 500;
    height: 24px;
    width: 120px;
  }

  input:focus {
    outline: none;
    border-color: none;
  }
}

.input-large {
  input {
    height: 24px;
    width: 180px;
  }
}

.input-text-area {
  textarea {
    background: $color-gradient-light !important;
    margin: 0;
    padding: 0.6em;
    border: none;
    border-radius: 16px;
    color: $color-gray-dark;
    font-family: $font-inter;
    font-weight: 500;
    height: 180px;
    width: 180px;
  }

  textarea:focus {
    outline: none;
    border-color: none;
  }
}

.search-bar {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;

  input {
    margin: 0;
    padding: 0.6em;
    padding-left: 1em;
    border: 2px solid $color-gray-extra-light-transparent;
    border-radius: 16px;
    color: $color-gray-light;
    font-family: $font-inter;
    height: 42px;
    width: 60vw;
    text-transform: capitalize;
  }

  input:focus {
    outline: none;
    border-color: none;
  }

  .button-search-container {
    transform: translate(-100%, 0.25em);
    svg {
      transform: translate(-0.5em, 0.25em);
    }
  }
}

.input-select {
  min-width: 50%;
}

.select__control {
  background: $color-gradient-light !important;
  margin: 0 !important;
  padding-left: 0.6em !important;
  border: none !important;
  border-radius: 16px !important;
  color: $color-gray-dark !important;
  font-size: 12px !important;
  font-family: $font-inter !important;
  font-weight: 500 !important;
  max-height: 24px !important;
  min-height: 24px !important;
  min-width: 300px !important;
  max-width: 300px !important;
  text-transform: capitalize !important;
}

.css-1jqq78o-placeholder {
  padding-left: 5px;
}

.css-1hb7zxy-IndicatorsContainer {
  transform: translateY(-5px);
}

.select__menu {
  min-width: 200%;
  font-size: 12px !important;
}

.select__value-container {
  transform: translateY(-0.5em);
}
