.basket {
  display: flex;
  flex-direction: column;
  min-height:80vh;
  min-width: 90%;
  max-width: 90%;
  margin: 5%;
  margin-right: 0%;

  &__main {
  margin-bottom: 10%;
  &__list {
    &__container {
      &__body {
        &__edit {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  }

  .button-extra-large {
    justify-self: anchor-center;
    position: absolute;
    bottom: 15%;
    width: 90% !important;
  }

  &__warning-text {
    color: black;
    background-color: orange;
    padding: 5%;
    border-radius: 12px;
    z-index: 3002;
    text-align: center;
  }
}

.table-container {
  margin-top: 20px;
}

table {
  max-width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}
