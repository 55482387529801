.menu-bar {
    min-width: 320px;
    width: 100%;
    max-width: 420px;
  height: 95px;
  display: flex;
  position: fixed;
  padding-bottom: 7em;
  bottom: -5%;
  left: 0;
  z-index: 3000;

  &__background {
       min-width: 320px;
    width: 100%;
    max-width: 420px;
    height: 95px;
    background: $color-white;
    position: absolute;
    z-index: 0;
    //clip-path: path(
    //  'M33.48 0C37.253 0 40 3.598 40 7.37c0 16.284 13.2 29.483 29.483 29.483h13.034C98.8 36.853 112 23.653 112 7.371 112 3.598 114.747 0 118.52 0H336c13.255 0 24 10.745 24 24v47c0 13.255-10.745 24-24 24H24C10.745 95 0 84.255 0 71V24C0 10.745 10.745 0 24 0h9.48z'
    //);
  }

  &__left {
    transform: translate(70%, -50%);
    position: absolute;
    z-index: 1;

    .button-home {
      width: 64px;
      height: 64px;
      z-index: 10;
    }
  }

  &__right {
    display: flex;
    width: 100%;
    padding-left: 40%;
    padding-top: 2%;
    justify-content: space-evenly;
    z-index: 0;
  }
}
