button:disabled {
  background-color: #bfbfbf;
}

.button-small {
  width: fit-content;
  height: 32px;
  border: none;
  border-radius: 16px;
  background-color: $color-primary-dark;
  color: $color-white;
  padding: 0.1em 0.3em 0.1em 0.3em;
  font-weight: 700;
}

.button-large {
  width: 180px;
  height: 36px;
  border: none;
  border-radius: 16px;
  background-color: $color-primary-dark;
  color: $color-white;
  padding-top: 0.1em;
  font-weight: 700;
}

.button-extra-large {
  width: 100% !important;
  margin: auto;
  height: 48px;
  border: none;
  border-radius: 16px;
  background-color: $color-primary-dark;
  color: $color-white;
  padding-top: 0.1em;
  font-weight: 700;
}

.button:hover {
  cursor: pointer;
}

.button:focus {
  border-style: none !important;
  outline: 0 !important;
}

.button:active {
  transform: translateY(0.1em);
}

.button-round {
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 50%;
  background-color: $color-primary-dark;
  padding-top: 0.1em;
}

.button-round:hover {
  cursor: pointer;
}

.button-round:focus {
  border-style: none !important;
  outline: 0 !important;
  transform: translateY(0.1em);
}

.button-round:active {
  // transform: translateY(0.1em);
}

.round-small {
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  padding-top: 0.1em;
}

.button-icon {
  cursor: pointer;
}

.button-icon:hover {
  cursor: pointer;
}

.button-icon:focus {
  border-style: none !important;
  outline: 0 !important;
}

.button-icon:active {
  transform: translateY(0.1em);
}

.button-notifications {
  width: 32px;
  height: 32px;
  background: $color-gray-extra-light;
  border-radius: 50%;
}

.button-notifications-container {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 0.3em;
  position: relative;

  .status-pilot {
    position: absolute;
    top: 1.5em;
    left: 1.5em;
    z-index: 2;
  }

  .button-round-notifications {
    position: absolute;
    top: 0.1em;
    left: 0.05em;
  }
}

.button-back {
  z-index: 1;
  border-radius: 100%;
}

.status-pilot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: $color-primary-dark;
  position: absolute;
  top: 0.5em;
  left: 0.5em;
}

.button-add {
  width: 32px;
  height: 32px;
}

.button-create-item {
  &__button {
    width: 116px;
    height: 116px;
    background-color: white;
    border-radius: 16px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    .button-add {
      transform: scale(1.5);
    }
  }
}

.button-cancel {
  .button-cancel {
    width: 24px;
    height: 24px;
    background-color: $color-gray-extra-light;
  }
}

.button-subscribe {
  width: 100% !important;
  max-width: 100% !important;
  padding: 1.25em;
  display: flex;
  margin: 0.25em;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  justify-content: space-between;
  height: 48px;
  border: none;
  border-radius: 16px;
  background-color: $color-primary-dark;
  color: $color-white;
  font-weight: 700;
}

.button-unsubscribe {
  width: 100% !important;
  max-width: 100% !important;
  padding: 1.25em;
  display: flex;
  margin: 0.25em;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  justify-content: space-between;
  height: 48px;
  border: none;
  border-radius: 16px;
  background-color: $color-text-danger;
  color: $color-white;
  font-weight: 700;
}
