.search-result {
  max-width: 400px;
  height: 100%;

  &__top-bar {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin-bottom: 1em;

    &__top {
      display: flex;
      flex-direction: row;
      gap: 2em;
      .logo {
        transform: translate(2em, 0.5em);
        display: flex;
        flex-direction: column;
        gap: 0.3em;
      }
    }
  }

  &__main {
    max-width: 400px;
    width: 95%;
    height: max-content;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: auto;
    margin-bottom: 20%;

    &__gallery {

      &__title {
    height: fit-content;
    margin: 2em 0 2em;
    max-width: 90%;
    margin-left: 5%;
      }
    }

    &__action {
      margin-top: 1.5em;
      margin-bottom: 1em;
      text-align: center;
    }
  }
}
