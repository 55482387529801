.banner {
  background: $color-gradient-blue;
  display: flex;
  border-radius: 16px;
  padding: 1em;

  &__text {
    text-align: left;

    &__title {
      font-size: 18px;
      font-weight: 800;
      text-align: left;
      color: $color-primary-extra-light;
    }

    &__text {
      margin-bottom: 1em;
    }

    .button-small {
      padding: 0.1em 0.9em 0.1em 0.9em;
      background-color: #4949f5;
    }
  }

  &__icon {
    align-content: center;
  }
}
