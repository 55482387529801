.chat {
  margin: 5%;
  padding: 3% 0 20%;
}

.chat-rooms {
  &__list {
    width: 100%;
    min-height: 90vh;
    max-height: 90vh;
    background-color: white;
    padding: 0.5em;
    list-style-type: none;

    &__chat-room {
      margin: 1em;
      padding: 1em;
      background-color: $color-primary-extra-light;
      border-radius: 8px;
      display: flex;
      position: relative;
    }
  }
}

.chat-room {
  margin: 5%;
  margin-bottom: 10%;
  min-height: 130vh;
  padding: 3%;

  &__messages {
    &__list {
      background-color: white;
      border: 3px solid $color-primary-dark;
      border-radius: 5px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      height: 75%;
      overflow-y: scroll;
      padding: 5%;
      width: 100%;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }

  &__messages {
    &__bubble {
      display: flex;
      width: 100%;
      justify-content: right;

      &__message {
        background: $color-primary-mega-light;
        border-radius: 0.2em;
        padding: 0.5em;
        margin: 0.2em;
      }
    }

    &__bubble-other {
      display: flex;
      width: 100%;
      justify-content: left;

      &__message {
        background: $color-chat-bubble-green;
        border-radius: 0.2em;
        padding: 0.5em;
        max-width: max-content;
        margin: 0.2em;
      }
    }

    &__list {
      flex-direction: column-reverse;
      display: flex;
      list-style-type: none;
      padding: 0.2em;
      min-height: 70vh;
      max-height: 70vh;
    }
  }

  &__form {
    justify-content: space-between;
    min-width: 100%;
    display: flex;
    margin: 1em 0 0 0;

    &__input {
      padding: 0 0 0 3px;
      border: 3px solid $color-primary-dark;
      min-width: 75%;
      min-height: 32px;
    }

    &__action {
      height: 24px;
      min-width: max-content;
      margin: 1em 0 0 0;
      padding: 0 3px 0 3px;
      border-radius: 5px;
    }
  }
}
