.dashboard-public {
  max-width: 400px;
  height: 100%;

  &__top-bar {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin-bottom: 1em;

    &__top {
      display: flex;
      flex-direction: row;
      gap: 2em;
      .logo {
        transform: translate(2em, 0.5em);
        display: flex;
        flex-direction: column;
        gap: 0.3em;
      }

      .notifications {
        transform: translate(-2.5em, 1em);
      }

      .search-bar {
        margin-top: 0.5em;
      }
    }

    &__down {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 4%;

      .settings {
        transform: translateX(-100%);
      }
    }
  }

  &__main {
    max-width: 400px;
    width: 95%;
    height: max-content;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: auto;
    margin-bottom: 20%;

    &__block {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }

    &__action {
      margin-top: 1.5em;
      margin-bottom: 1em;
      text-align: center;
    }
  }
}
