.session {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  width: 90%;
  height: 100%;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-bottom: 40%;

  &__title {
    align-self: center;
  }

  form {
    display: contents;
    width: 90%;
  }

  &__block {
    display: flex;
    width: 100%;
    justify-content: space-evenly;

    &__picture {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;

      &__placeholder {
        align-self: center;
        background: $color-gray-light;
        border-radius: 50%;
        height: 100px;
        width: 100px;

        .button-add {
          position: absolute;
          height: 100px;
          width: 100px;

          .button-round {
            position: absolute;
            right: -4em;
            bottom: -2em;
          }
        }
      }

      &__input {
        align-self: end;
        width: 62%;
        margin-top: 1em;
        margin-bottom: 1em;
      }
    }

    .input-text-area {
      width: 80%;

      textarea {
        width: 100%;
      }
    }
  }

  .checkbox {
    margin-top: 1em;
    margin-bottom: 1em;

    input {
      transform: translateX(10px);
    }
  }

  .button-large {
    align-self: center;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  &__options {
    width: 180px;
    position: relative;
    align-self: center;
    margin-top: 1em;
  }
}

.sign-in {
  margin-bottom: 10em;
}
