.items {
  padding: 0 5% 20% 5%;

  &__top {
    position: relative;
    display: flex;
    width: 90%;
    margin-left: 5%;
    margin-top: 1em;
    margin-bottom: 1em;
    flex-direction: row;
    justify-content: space-between;

    &__left {
      display: flex;
      position: relative;

      &__user-name {
        align-self: center;
        margin-left: 1em;
      }
    }
  }

  &__tools {
    position: relative;
    display: flex;
    justify-content: space-around;
    margin-bottom: 1em;
  }

  &__cart {
    &__bottom {
      border-radius: 16px;
      background-color: $color-white;
      padding: 2em;
      position: relative;
      display: flex;
      justify-content: center;

      &__details {
        min-width: 80%;

        &__subtotal {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1em;
        }

        &__shipping {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1em;
        }

        &__total {
          display: flex;
          border-top: 1px solid $color-gray-extra-light;
          padding-top: 1em;
          justify-content: space-between;
          margin-bottom: 1em;
        }
      }
    }
  }

  &__group {
    &__title {
      max-width: 90%;
      margin-left: 5%;
    }
  }
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 1em 2.5% 40% 2.5%;
  height: 100%;

  &__top {
    position: relative;
    display: flex;
    margin-top: 1em;
    margin-bottom: 1em;
    flex-direction: row;
    justify-content: space-between;

    &__left {
      display: flex;
      position: relative;

      &__user-name {
        align-self: center;
        margin-left: 1em;
      }
    }
  }

  &__main {
    background-color: white;
    padding: 1em;
    border-radius: 8px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    margin-bottom: 2em;

    &__top {
      display: flex;
      justify-content: space-between;
    }

    &__rating {
      display: flex;
      justify-content: center;
      align-items: center;

      &__reviews {
        &__label {
          display: flex;
        }

        &__number {
          padding-right: 0.3em;
          color: gray;
        }
      }
    }

    &__description {
      margin-top: 1em;
      margin-bottom: 1em;

      textarea {
        width: 100%;
      }
    }

    &__details {
      width: 100%;
      display: flex;
      margin-bottom: 1em;
      place-content: center;

      .input {
        justify-items: center;

        label {
          padding-left: unset !important;
        }

        input {
          width: 75%;
          text-align-last: center;
        }
      }
    }

    &__bottom {
      display: flex;
      position: relative;
      justify-content: space-around;
      margin-bottom: 2em;
      min-width: 100%;

      &__button {
        margin: 0.3em;
        min-width: 100%;

        button {
          min-width: 100%;
        }
      }
    }
  }

  &__handler {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 1em;

    &__cart {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__picture {
    z-index: 2;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;

    &__input {
      margin-top: 1em;
      align-self: self-end;
    }

    img {
      max-height: 50vh;
    }
  }
}
