.burger-carousel-frame {
  .burger-carousel {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 1em;
    max-width: 360px;
    overflow-x: scroll;
    overflow-y: hidden;

    &__items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      overflow: visible;

      &__item {
        margin-right: 0.5em;
      }
    }
  }
}

.burger-carousel::-webkit-scrollbar {
  //display: none;
  visibility: hidden;
}

.banner-carousel {
  display: flex;
  width: 100%;
  height: 120px;
  flex-direction: column;
  overflow-x: scroll;

  &__items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: overlay;

    &__item {
      display: flex;
      flex-direction: row;
      background: $color-gradient-light;
      border-radius: 16px;
      min-width: 244px;
      height: 120px;
      margin-right: 0.5em;
      padding: 0.5em;
      padding-left: 1em;
      padding-right: 1em;

      &__left {
        &__title {
          color: $color-primary-dark;
        }

        &__description {
          color: $color-gray-dark;
          line-height: 16px;
          margin-bottom: 0.7em;
        }
      }

      &__right {
        img {
          width: 85px;
          height: 85px;
        }
      }
    }
  }
}

.gallery {
  height: fit-content;
  margin: 2em 0 2em;
  min-width: 90%;
  max-width: 90%;

  &__items {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1em;
    margin-top: 2%;

    &__item:active {
      transform: translateY(0.1em);
    }

    &__item {
      justify-self: center;
    }
  }

  &__cart {
    display: flex;
    flex-direction: column;
    margin-bottom: 25%;

    &__item {
      border: 1px solid #cfcbcb6e;
      border-radius: 16px;
      margin-bottom: 1em;
    }

    &__order {
      border: 1px solid $color-gray-extra-light;
      border-radius: 16px;
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }
}
