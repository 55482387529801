.item-adder {
  display: flex;
  place-content: space-between;
  min-width: 60px;
  transform: translateX(-10%);

  &__total {
    align-self: center;
    display: flex;
    margin-left: 0.3em;
    margin-right: 0.3em;
  }
}
