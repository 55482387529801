* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

html,
body {
  background-color: $color-gray-extra-light-transparent;
  overflow: auto;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  min-width: 320px;
  max-width: 420px;
}

.language {
  position: absolute;
  top: 1em;
  z-index: 100;
  width: fit-content;
  height: 24px;
  overflow: hidden;
  display: flex;
  right: 1em;
}

.background-circle {
  width: 400px;
  height: 400px;
  position: absolute;
  background: $color-gradient-medium;
  top: -5%;
  left: -20%;
  border-radius: 50%;
}

.img-user-small {
  align-self: center;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.img-user {
  align-self: center;
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

.img-placeholder {
  align-self: center;
  background: $color-gray-light;
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

.img-placeholder-large {
  align-self: center;
  background: $color-gray-light;
  border-radius: 50%;
  height: 200px;
  width: 200px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.visible {
  visibility: visible;
}

.not-visible {
  visibility: hidden;
}

.app-name {
  align-self: end;
  transform: translateY(0.3em);
}

.error {
  position: absolute;
  min-height: fit-content;
  min-width: 100%;
  max-width: 90%;
  text-align: center;
  top: 30%;
  font-size: 18px;
  color: red;
  background: white;
  z-index: 100;
}

.input-error {
  min-height: fit-content;
  min-width: 100%;
  max-width: 90%;
  text-align: center;
  font-size: 18px;
  color: red;
  background: white;
}

.title {
  align-self: center;
  z-index: 2;
}

.text-warning {
  color: $color-text-warning;
}

.text-success {
  color: $color-text-success;
}

.text-danger {
  color: $color-text-danger;
}

.line {
  border-top: 1px solid $color-gray-extra-light;
  margin-bottom: 2em;
}

.hidden {
  visibility: hidden;
}
