.modal-category-name-suggestion {
  background: $color-white;
  padding: 1em;
  border-radius: 16px;
  border: 2px dashed $color-primary-dark;
  position: fixed;
  z-index: 100;
  margin-top: 50%;

  &__title {
    margin-top: 1em;
  }

  &__box {
    margin-top: 0.25em;
    margin-bottom: 1em;
    padding-top: 1em;
    position: relative;
    max-width: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;

    &__text {
      margin-bottom: 0.25em;
    }

    &__name {
      color: $color-text-success;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-evenly;

    button {
      margin-bottom: 0.5em;
    }
  }
}

.modal-confirmation {
  position: fixed;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 0%;
  left: 0%;
  min-width: 100vw;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.431372549);
  z-index: 1;

  &__dialog {
    display: flex;
    flex-direction: column;
    place-content: space-evenly;
    width: 80%;
    height: 20%;
    background: white;
    border-radius: 8px;
    padding: 1em;
    padding-left: 2em;
    text-align: start;

    &__action {
      display: flex;
      justify-content: space-around;
      margin-top: 1em;
    }
  }
}

.modal-rating {
  position: fixed;
  background: $color-white;
  top: 0%;
  min-width: 100%;
  min-height: 100%;
  z-index: 2;
  text-align: center;
  display: flex;
  align-items: center;

  &__dialog {
    position: relative;
    min-width: 100%;
    height: 20%;
    display: flow-root;

    .stars {
      display: flex;
      text-align: center;
      justify-content: center;
      margin-top: 1em;
    }
  }

  button {
    margin-top: 1em;
  }
}



.modal-add-item-to-list {
  position: fixed;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top:0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background: $color-gray-extra-dark-transparent;
  z-index: 3001;
  .modal-add-item-to-list-content {
    min-height: 80%;
    min-width: 80%;
    background-color: $color-white;
    padding: 2em;
    border-radius: 16px;
    &__title {
      text-align: center;
    }
    &__container {
      &__item {
        .input-large {
          justify-content: center;
        }
        .input-text-area {
              justify-content: center;
        }
      }
    }
    &__actions {
      margin: 2em;
      .button-small {
        margin-bottom: 1em;
        min-width: 100%;
      }
    }
  }
}