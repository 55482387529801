.order {
  width: 100%;
  min-height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 0;

  &__body {
    display: flex;
    justify-content: center;
    text-align-last: center;
  }

  &__top {
    position: relative;
    display: flex;
    width: 90%;
    margin-left: 5%;
    margin-top: 1em;
    margin-bottom: 1em;
    flex-direction: row;
    justify-content: space-between;

    &__left {
      display: flex;
      position: relative;

      &__user-name {
        align-self: center;
        margin-left: 1em;
      }
    }
  }

  &__user {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: 1px solid $color-gray-extra-light;
    padding-left: 5%;
    padding-right: 5%;

    &_left {
      &__field {
        margin-bottom: 0.75em;
      }
    }
  }

  &__details {
    &__show {
      &__group {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 5%;
        padding-right: 5%;

        &__field {
          display: flex;
          place-content: space-between;
          flex-flow: column;
          margin-bottom: 1em;
          margin-left: 5%;
          min-width: 50%;
          max-width: 50%;
        }
      }
    }

    &__radio {
      border: unset;
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-around;
      border-top: unset;
      border-bottom: unset;
      margin-bottom: 1em;
    }

    &__fields {
      width: 80%;
      margin-left: 10%;

      input {
        margin-bottom: 1em;
      }

      &__location {
        width: 50%;
        display: flex;
        flex-direction: row;

        &__button {
          margin-left: 1em;
          display: flex;
          flex-direction: column;
          min-width: 50%;
        }
      }

      textarea {
        width: 100%;
        margin-bottom: 2em;
      }
    }
  }

  &__cart {
    &__bottom {
      flex-direction: column;
      border-radius: 16px;
      background-color: $color-white;
      padding-top: 1em;
      padding-bottom: 30%;
      position: relative;
      display: flex;
      justify-content: center;
      margin: 0 2% 0 2%;

      &__details {
        &__products {
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 1em;

          &__img {
            display: flex;
            flex-direction: column;
            max-width: 60px;
            text-align: center;
          }

          &__headers {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 1em;
          }

          &__price {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
          }
        }

        &__subtotal {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1em;
          .input {
            display: flex !important;
            min-width: 100%;
            justify-content: space-between;
            text-align: end;
            input {
              text-align: end;
            }
          }
        }

        &__shipping {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1em;
          .input {
            display: flex !important;
            min-width: 100%;
            justify-content: space-between;
            input {
              text-align: end;
            }
          }
        }

        &__total {
          display: flex;
          border-top: 1px solid $color-gray-extra-light;
          padding-top: 1em;
          justify-content: space-between;
          margin-bottom: 1em;
        }

        button {
          margin-bottom: 1em;
        }
      }
    }
  }
}
