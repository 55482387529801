$color-primary-dark: #40beaf;
$color-primary-light: #33deca;
$color-primary-extra-light: #91ece1;
$color-primary-mega-light: #cffff9;

$color-gray-light: #646464;
$color-gray-extra-light: #d4d4d4a3;
$color-gray-extra-light-transparent:#d4d4d44f;
$color-gray-extra-dark-transparent:#0204039c;
$color-gray-dark: #494949;

$color-white: #fff;
$color-black: #000;
$color-blue: #4949f5;
$color-text-antracite: #292929;
$color-text-antracite_light: #292929;

$color-orange: #ed8a19;

$color-gradient: linear-gradient(180deg, #40beaf 0%, #33deca 100%);
$color-gradient-medium: linear-gradient(180deg, #3ecbba 0%, #25f3db 100%);
$color-gradient-light: linear-gradient(90deg, #d5fffa 0%, #16ffe4 40%);
$color-gradient-blue: linear-gradient(90deg, #bebef3 .0001%, #4949f5 100%);

$color-text-warning: #ffc107;
$color-text-success: #4caf50;
$color-text-danger: #f44336;

$color-banner-blue:  #4949f5;

$color-chat-bubble-green:  #40beaf;
$color-chat-bubble-gray: rgb(238 238 238);
// FONT
$default-font: "Inter", sans-serif;
$default-font-size: 14px;

// GRID
$grid-width: 160rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal-small: 2rem;
$gutter-horizontal: 6rem;
$gutter-horizontal-large: 20rem;

// Border
.border-theme {
  border-radius: 16px;
  border: $color-gray-extra-light;
}

// Input
.input-theme {
  background-color: $color-gradient;
  border-radius: 16px;
  border: unset;
  padding: 16px;
  font-size: 14px;
  font-family: $default-font;
  color: $color-gray-dark;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border: 1px solid $color-primary-dark;
  }
}

// Template classes are define here
.grid-16 {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-column-gap: 16px;
  padding-left: 32px;
  padding-right: 32px;
  table-layout: fixed;
}

// Common
.visible {
  visibility: 'visible';
}

.not-visible {
  visibility: 'hidden';
}
