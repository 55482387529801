.card {
  width: 8em;
  min-height: 10em;
  border: 1px solid #d4d4d4;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: $color-white;
  padding: 1em 0 1em 0;
  gap: 0.1em;
  position: relative;
  z-index: 2;

  &__button-plus {
    margin-top: 1em;
    margin-left: 1em;
    position: absolute;
    right: 16px;
    z-index: 5;

    svg:hover {
      transform: scale(0.5em);
    }
  }

  &__img {
    background-color: pink;
    width: 6em;
    max-height: 6em;
    border-radius: 16px;
    //margin-top: 1em;
    //margin-left: 1em;
    //position: absolute;
  }

  &__title {
    text-transform: capitalize;
    text-align: center;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    margin-top: 10px;
  }

  &__price {
    line-height: 5px;
    font-weight: 500;
    font-size: 16px;
    margin-top: .25;
    position: relative;
  }
}

.card:active {
  transform: translateY(0.1em);
}

.card-item-cart {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 320;
  height: 100px;
  z-index: 0;

  &__img {
    width: 82px;
    height: 55px;
  }

  &__quantity {
    .item-adder {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  &__text {
    &__price-measurement {
      display: flex;
      flex-direction: inherit;
    }
  }

  &__right {
    align-self: start;
    margin-top: 0.5em;

    &__total {
      position: relative;
      margin-top: 1em;
      text-align-last: center;
    }
  }
}

.card-item-order {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 320;
  height: 100px;
  z-index: 0;

  &__img {
    width: 82px;
    height: 55px;
  }

  &__text {
    &__price-measurement {
      display: flex;
      flex-direction: inherit;
    }
  }

  &__right {
    align-self: start;
    margin-top: 0.5em;

    &__total {
      position: relative;
      margin-top: 1em;
      text-align-last: center;
    }
  }
}
